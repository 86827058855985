// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import { device } from 'components/device';
import Layout from 'components/layout';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import WhatPatientsSayAboutUs from 'components/home/whatPatientsSayAboutUs';
import { SubMenu } from '../../../components/subMenu';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 0 0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    display: ${props => props.isDesktop && 'flex'};
    padding: 0 20px;
  }

  .cardsCdi {
    section {
      width: 100%;
      div {
        width: 100%;
        margin-left: 0;
        @media ${device.laptop} {
          display: flex;
        }
        div {
          > ul {
            width: 100%;
            @media ${device.laptop} {
              display: flex;
              flex-direction: column;
              margin: 0;
            }
            > li {
              width: 100%;
              margin-right: 0;
              > h2 {
                font-weight: 400;
                width: 65%;
                padding-bottom: 1.875rem;

                @media ${device.laptop} {
                  width: 100%;
                  margin: 0;
                  padding: 0;
                  padding-bottom: 1.875rem;
                }
              }
              :hover {
                box-shadow: unset;
              }
              :first-child {
                margin-right: 0;
                padding: 0 15px 0 0 !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                  border-bottom: 1px solid #c6c8cc;
                  border-radius: unset;
                }
              }
              :last-child {
                margin-right: 0;
                padding: 0 0 0 15px !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                }
              }
              @media ${device.laptop} {
                height: unset;
              }
              ul {
                grid-template-columns: 1fr;
                width: 100%;
                li {
                  margin-right: 0;
                  @media ${device.laptop} {
                    width: 100%;
                    height: unset;
                  }
                  p a {
                    display: contents;
                  }
                  h3 {
                    width: 100%;
                    > a {
                      :first-child {
                        @media ${device.laptop} {
                          display: flex;
                          width: 100%;
                        }
                        @media ${device.tablet} {
                          width: 100%;
                        }
                      }
                      :last-child {
                        @media ${device.laptop} {
                          top: 0;
                          right: 0;
                        }
                      }
                    }
                    @media ${device.laptop} {
                      width: 100%;
                      position: relative;
                    }
                  }
                }
                @media ${device.laptop} {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Part = styled.div`
  position: relative;
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  padding-bottom: ${props => props.paddingBottom && '70px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }



  :first-child {
    display: ${props =>
      props.displayFirstChild ? props.displayFirstChild : 'flex'};
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }
`;

const GridWhoPatientsSayAboutUs = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  -webkit-column-gap: 30px;
  column-gap: 30px;

  background-color: #f4f5f7;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    margin: 0 -20px;
  }

  @media ${device.tablet} {
    margin: 0 -20px;
  }

  @media ${device.mobile} {
    display: flex;
    justify-self: center;
    height: auto;
  }

  @media ${device.mobile320} {
    padding-left: 0;
    margin: 0 -40px;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / span 12'} isCDIUnits>
        <FeaturedMarketing
          markdown={getMarkdown(page, 'cktyb5tjs04rm0b27qozxb2l1', true)}
          breadCrumb={getMarkdown(page, 'cku4g494816he0c73wv0xb6y6', true)}
          asset={getMarkdown(page, 'cktyb5tjs04rm0b27qozxb2l1', false, true)}
          isOncologia
          boxContentHeightDesk="24rem"
          boxInfoHeightLap="20rem"
          containerMarginBottomTab="1.875rem"
          containerMarginBottomMob="0"
          boxInfoPaddingRightLap
          boxInfoTextWidthDesk="29.125rem"
          boxInfoPaddingLeftEmDesk="2rem"
          boxInfoParagraphImgPaddingRMobile320="0.5rem"
          boxInfoPaddingRImgDesk2="0.625rem"
          boxInfoParagraphImgWidth="unset"
          boxInfoParagraphImgHeight="unset"
          boxInfoTagPColor="#231f20"
          boxInfoParagraphImgLastTransformDesk="translate(0, 30%)"
          boxInfoPFontWeightNormal
          boxInfoTagEmDisplay="block"
          titleWidthMob="10rem"
          titleWidthMob320="50%"
          titleFontSizeMob360="1.25rem"
          titleFontSizeMob320="1.125rem"
          boxContentFlexDirectionLap="unset"
          boxContentFlexDirectionTab="column-reverse"
          marginBottomTitleDesk="2.5rem"
          widthImgMarketingLap="25rem"
          widthImgMarketingTab="17rem"
          widthImgMarketingMobile="12.313rem"
          positionTopImageMarketingMobile="-0.5rem"
          overflowContainerMobile="hidden"
          positionLeftImageMarketingMobile="0.75rem"
          positionLeftImageMarketingMobile320="1.725rem"
          marginTopBoxInfoLap="0"
          marginTopBoxInfoTab="-11rem"
          marginTopBoxInfoMob="-8rem"
          boxImagesImgOuterWrapperWidth="unset"
        />
      </Part>
      <Part gridColumn={'2 / -2'} borderBottom>
        <SubMenu
          markdown={getMarkdown(page, 'ckud2sh28ssjl0c28glfiwyfg', false)}
          isMarginPageVoluntariado
        />
      </Part>
      <Part gridColumn={'2 / -2'} notLeft>
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktybrfbs4yez0b79jg7g3ir9', true)}
          fragments={getMarkdown(page, 'cktybrfbs4yez0b79jg7g3ir9')}
          padding
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          contentTagImgTransform="unset"
          withoutBorderTop
          contentImgWidthLaptop="100%"
        />
      </Part>
      <GridWhoPatientsSayAboutUs>
        <Part gridColumn={'1 / -1'} notLeft displayFirstChild="unset">
          <WhatPatientsSayAboutUs
            withoutAvatarImage
            markdown={getMarkdown(page, 'cktyc0mcg0qux0b2743ov8ttn', true)}
            fragments={getMarkdown(page, 'cktyc0mcg0qux0b2743ov8ttn', false)}
          />
        </Part>
      </GridWhoPatientsSayAboutUs>
      <Part gridColumn={'2 / -2'} borderBottom notLeft>
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktybg2dk3efk0b72j8bzn8aq', true)}
          fragments={getMarkdown(page, 'cktybg2dk3efk0b72j8bzn8aq')}
          padding
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          withoutBorderTop
        />
      </Part>
      <Part gridColumn={'2 / -2'} borderBottom notLeft>
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktyb16404du60b79w7m0lecy', true)}
          fragments={getMarkdown(page, 'cktyb16404du60b79w7m0lecy')}
          padding
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          withoutBorderTop
        />
      </Part>
      <Part gridColumn={'2 / -2'} borderBottom notLeft>
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktyav0ns40970c776bxye9x5', true)}
          fragments={getMarkdown(page, 'cktyav0ns40970c776bxye9x5')}
          padding
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          withoutBorderTop
        />
      </Part>
      <Part gridColumn={'2 / -2'} notLeft>
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktyansmo45ri0b79ii52ebrc', true)}
          fragments={getMarkdown(page, 'cktyansmo45ri0b79ii52ebrc')}
          padding="4.375rem 0 3.125rem"
          marginListDesk="0 0 1.875rem"
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          titleUlDisplay="none"
          isOncologia
          withoutBorderTop
          contentMarginBottom="0"
          contentTagUlListStyleType="none"
          contentTagLiImgMargin="0 0.625rem 0 0"
          contentUlListWithMultiLine
          contentPChild7InfoCard
          contentPChild8EmailCard
        />
      </Part>
    </GridContainer>
  );
};

const Volunteering = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);
  const [, setRedirect] = useState(false);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
    setRedirect(true);
  });

  const getImage = getMarkdown(page, 'cktyb5tjs04rm0b27qozxb2l1', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cktygqnhk1jz90a325didspct" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                    datafragment
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <Volunteering page={response.gcms.site.pages} />;
      }}
    />
  );
};
